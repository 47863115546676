#home_post_nav {
  @apply border-b border-grey-4;

  input + label {
    @apply text-grey-1 text-18px pb-1.5;

    border-bottom: 3px solid rgba(0,0,0,0);
  }
  .icon {
    @apply inline-block bg-grey-1;
  }
  input:checked + label {
    @apply text-white border-primary;

    .icon {
      @apply bg-white;
    }
  }
}

#post-item-collection {
  @apply flex-1 grid gap-x-4 gap-y-8 smd:gap-x-6 md:gap-y-10 grid-cols-1 smd:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 4xl:grid-cols-6;

  .campaign-post {
    @apply relative;

    // &:after {
    //   @apply absolute w-10 h-10 -top-0.5 -right-0.5;
    //   @apply bg-no-repeat bg-center bg-contain;

    //   content: '';
    //   background-image: url('~images/home/star.svg');
    // }
  }

  .post-item {
    .post-image {
      @apply relative;

      a{
        @apply rounded-7px overflow-hidden;

        .image-mask {
          @apply absolute bottom-0 left-0 w-full h-7 hidden rounded-b-8px;

          backdrop-filter: blur(1.5px);
        }
      }
      label:not(.close) {
        @apply w-8 h-8 absolute right-4 bottom-4 hidden;
        @apply bg-no-repeat bg-center rounded-full cursor-pointer;

        background-color: var(--color-grey-3);
        background-size: 20px 20px;
        background-image: url('~images/icons/shared.svg');

        &:hover {
          background-color: var(--color-grey-5);
        }
      }
      .post-price-container {
        @apply hidden;
      }

      .post-data-container {
        @apply hidden;
      }

      &:hover, input:checked ~ {
        a .image-mask {
          @apply block;
        }
        label {
          @apply block;
        }
        .post-price-container {
          @apply flex;

          margin-bottom: 3px
        }
        .post-data-container {
          @apply flex;
        }
      }

      .shared-mask {
        @apply fixed top-0 left-0 hidden flex items-center justify-center text-center;

        background: rgba(0,0,0,.5);

        width: 100%;
        height: 100%;

        @media (min-width: 780px) {
          @apply absolute top-full left-0 h-auto;

          width: calc(100% + 16px);
          background: none
        }

        label.close {
          @apply absolute block md:hidden w-4 h-4 top-1.5 right-0 cursor-pointer;
        }

        .shared-content {
          @apply p-4 bg-grey-4 rounded-7px;

          @media (min-width: 780px) {
            box-shadow: 8px 8px 12px rgba(0,0,0,.3)
          }
        }

        .shared-icons {
          @apply grid grid-cols-4 gap-y-4;

          .shared-icon {
            @apply flex flex-col items-center text-grey-5 cursor-pointer;
          }

          hr {
            @apply col-span-4 border-grey-5;
          }
        }
      }

      input:checked ~ .shared-mask {
        @apply flex items-center justify-center;

        z-index: 10;

        @media (min-width: 780px) {
          @apply block;

          margin: 0 -8px
        }
      }

      .free-tag {
        color: #19791D;
        background-color: #2CFF67;
        border-radius: 4px;
        line-height: 16px;
      }

      .pv-tag {
        background-color: #E60044;
        border-radius: 4px;
        line-height: 16px;
      }

    }
    .post-info {
      @apply rounded-b-7px overflow-hidden;

      .display-name {
        color: #C4C4C4
      }
    }
    .post-title {
      @apply text-18px;
    }
    .creator-info {
      @apply block my-1;
    }
    .post-price {
      @apply text-12px;
      text-shadow: 0px 1px 4px rgba(0,0,0,.7);
    }
    .post-original-price {
      @apply ml-2 text-12px text-grey-5 line-through;
      text-shadow: 0px 1px 4px rgba(0,0,0,.7);
    }
    .post-view-count {
      font-size: 12px;
      color: #EAEAEA;
      text-shadow: 0px 4px 4px rgba(0,0,0,.05);

      .icon {
        @apply bg-no-repeat bg-center bg-contain;
        background-image: url('~images/icons/view-count.svg');
        filter: invert(10%) sepia(10%) saturate(0%) hue-rotate(180deg) brightness(95%) contrast(87%) drop-shadow(5px 5px 5px rgba(0,0,0,.15));
      }
    }
    .post-likes-count {
      font-size: 12px;
      color: #EAEAEA;
      .icon {
        @apply bg-no-repeat bg-center bg-contain;
        background-image: url('~images/icons/likes.svg');
      }
    }
    .post-downloads-count {
      font-size: 12px;
      color: #EAEAEA;
      .icon {
        @apply bg-no-repeat bg-center bg-contain;
        background-image: url('~images/download.svg');
      }
    }
  }

  .post-coming-soon, .post-item-not-found, .post-item-loading {
    @apply smd:col-span-2 md:col-span-3 lg:col-span-4 2xl:col-span-5 4xl:col-span-6 flex items-center justify-center min-h-[280px] md:min-h-[400px] h-full;

    .icon {
      @apply bg-no-repeat bg-center bg-contain w-12 h-12;
    }

    .title {
      @apply mt-4 mb-2 text-20px md:text-32px font-medium;
    }
  }

  .post-coming-soon {
    @apply flex-col bg-grey-7 rounded-xl;

    .content {
      @apply text-14px md:text-16px;
    }
    .release_time {
      @apply mt-6 text-14px italic text-grey-5 font-bold;
    }
  }

  .post-item-not-found {
    @apply flex-col bg-grey-7 rounded-xl py-[80px] smd:py-[120px] px-6;

    .icon {
      background-image: url('~images/icons/search.svg');
      background-repeat: no-repeat;
    }

    .popular-tags {
      @apply my-8 md:mx-auto md:w-3/5 xl:w-1/2 2xl:w-1/3 max-w-[560px];
      .popular-tag {
        @apply inline-block m-2 my-3;

        a {
          @apply px-4 py-2 rounded-full hover:opacity-70;
        }

        &.keyword-tag a{
          background: #00939C;
        }

        &.creator-tag a{
          background: linear-gradient(115deg, #00939C, #3D5CB8, #9C07E3);
        }
      }
    }
  }

  .post-item-loading {
    .skeleton-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px;
    }
    
    .post-item-skeleton {
      border-radius: 8px;
      overflow: hidden;
    }
    
    .skeleton-image {
      height: 150px;
    }
    
    .skeleton-info {
      padding: 10px;
    }
    
    .skeleton-title {
      height: 20px;
      background: #e0e0e0;
      margin-bottom: 10px;
    }
    
    .skeleton-author {
      height: 15px;
      background: #e0e0e0;
      width: 60%;
    }
  }
}

#category-filter {
  input {
    display: none;

    ~ .cate-session {
      display: none;
    }

    &:checked {
      + .cate-session {
        @apply block smd:flex;
      }
    }
  }
}

#index_content {
  #advance_filter {
    @apply hidden -mx-6 smd:mx-auto
  }
}

#advance_checkbox:checked {
  ~ .filter-bar {
    label.post-filter .icon {
      @apply bg-primary hover:bg-orange-10;
    }
  }

  ~ #index_content {
    @apply grid gap-x-4 smd:gap-6 smd:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 4xl:grid-cols-6;

    > label .icon {
      transform: rotate(180deg);
    }

    #advance_filter {
      @apply block smd:fixed smd:h-full;

      @media (min-width: 705px) {
        transform: translate(-32px, var(--tw-translate-y));
        width: calc(100% / 3 - 112px / 3 + 32px);
      }

      @media (min-width: 1024px) {
        transform: translate(0, var(--tw-translate-y));
        width: calc(100% / 4 - 232px / 4);
      }

      @media (min-width: 1536px) {
        width: calc(100% / 5 - 256px / 5);
      }

      @media (min-width: 1920px) {
        width: calc(1640px / 6);
      }
    }

    #post_index {
      @apply smd:col-start-2 smd:col-end-4 lg:col-end-5 2xl:col-end-6 4xl:col-end-7;

      #post-item-collection {
        @apply smd:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-5;
      }
    }
  }
}

.price_range_bar{
  font-weight: 600;
  line-height: 28px;
  font-size: 12px;
  text-align: center;
}


#profile-dashboard {
  #designer-post-collections {
    @apply flex flex-wrap gap-6;

    .collection-item {
      @apply w-full transition-all duration-300;

      &:hover {
        transform: translateY(-5px);
        box-shadow: rgba(38, 38, 38, 0.05) 0px 2px 15px 2px;
      } 

      @media (min-width: 560px) {
        width: calc(50% - 12px);
      }

      @media (min-width: 1024px) {
        width: calc(33% - 14px);
      }
    }
  }
  #post-item-collection {
    @apply flex-1 grid gap-4 smd:gap-6 grid-cols-1 smd:grid-cols-2 md:grid-cols-3 lg:grid-cols-4;
  }
}
